/* eslint-disable default-param-last */
import { loginConstants } from "./loginAction";

const initialState = {
  userDetails: {},
  isLoggedIn: false,
  username: "",
};


const loginReducer = (state = initialState, action) => {
  switch (action.type) {
    case loginConstants.SET_USER_DETAILS:
      return {
        ...state,
        userDetails: action.payload.userDetails,
      };
    case loginConstants.SET_IS_LOGGED_IN:
      return {
        ...state,
        isLoggedIn: action.payload.status,
      };
    case loginConstants.SET_USERNAME:
      return {
        ...state,
        username: action.payload.username,
      };

    default:
      return state;
  }
};

export default loginReducer;