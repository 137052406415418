import './App.css';
import './assets/css/custom.css';
import { Suspense } from 'react';
import RoutesPage from './routes';
import { BrowserRouter } from 'react-router-dom';
import BootstrapToast from "./components/toast";
import ThemeContainer from './containers/theme-container';

function App() {
  return (
    <BrowserRouter>
      <BootstrapToast />
      <Suspense>
        <ThemeContainer>
          <RoutesPage />
        </ThemeContainer>
      </Suspense>
    </BrowserRouter>
  );
}

export default App;
