/* eslint-disable multiline-comment-style */
import api_end_point from "../../../API/api-end-point";
import apiServices from "../../../API/api_service";

const sSOSignUp = async (values) => {
  const data = {
    username: values.username,
    password: values.password,
    name: values.name,
    mobile: '+' + values.mobile,
  };
  const url = api_end_point.SSO_SIGNUP;
  const result = await apiServices.post(url, data);
  return result;
};

const sSOConfirmPassword = async (values) => {
  const url = api_end_point.SSO_CONFIRM_PASSWORD;
  const result = await apiServices.post(url, values);
  return result;
};

export {
  sSOSignUp,
  sSOConfirmPassword,
}