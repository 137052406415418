import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import mailIcon from "../../assets/icons/mail-icon.svg";
import padLockIcon from "../../assets/icons/padlock-icon.svg";
import visibleEyeIcon from "../../assets/icons/visible-eye-icon.svg";
import hideEyeIcon from "../../assets/icons/hide-eye-icon.svg";
import userIcon from "../../assets/icons/user.svg";
import phoneCallIcon from "../../assets/icons/phone-call.svg";
import Regex from "../../constants/regex";
import useAlert from "../../hooks/use-alert";
import { sSOSignUp } from "./services/signUpServices";
import { setUserName } from "../login/redux/loginAction";
import { setRegistrationDetail } from "../../utils/token";

const SignUp = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { showAlert } = useAlert();

  const [isViewPassword, setIsViewPassword] = useState(true);
  const [isViewConfirmPassword, setIsViewConfirmPassword] = useState(true);
  const [showLoader, setShowLoader] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const initialValues = {
    name: "",
    username: "",
    mobile: "",
    password: "",
    confirmPassword: ""
  };

  const formSchema = Yup.object().shape({
    name: Yup.string()
      .trim()
      .required("Name must not be empty"),
    username: Yup.string()
      .trim()
      .required("User email must not be empty")
      .matches(Regex.mailRegex, "Invalid email"),
    mobile: Yup.string()
      .trim()
      .required("Mobile number must not be empty")
      .test('len', 'Mobile number must be 10 digits', val => val.length === 10)
      .test('len', 'Mobile number must be number', val => new RegExp(/^\d+$/).test(parseInt(val))),
    password: Yup.string()
      .trim()
      .required("Password must not be empty"),
    confirmPassword: Yup.string()
      .trim()
      .required("Confirm password must not be empty")
      .oneOf([Yup.ref('password'), null], 'Password does not match'),
  })

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: formSchema,
    onSubmit: async (values) => {
      setShowLoader(true);
      const result = await sSOSignUp(values);
      if (result && result.status) {
        showAlert("SUCCESS", "SUCCESS", result.message);
        setRegistrationDetail(values);
        dispatch(setUserName(values));
        navigate("/verify-otp");
      } else {
        showAlert("ERROR", "ERROR", result.message);
      }
      setShowLoader(false);
    },
  });

  return (
    <div className="page-content bg-style no-sidebar w-100 float-start">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-lg-5 mb-4">
            <div className="content-box-style">
              <h4 className="text-uppercase text-center mb-5">Sign Up</h4>
              <form action="" className="form-style">
                <div className="row">
                  <div className="col-12 mb-4">
                    <div className="input-group field-style">
                      <span className="input-group-text" id="basic-addon1">
                        <img src={userIcon} className="img-fluid" alt="" />
                      </span>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Your Name"
                        aria-label="Username"
                        aria-describedby="basic-addon1"
                        name="name"
                        {...formik.getFieldProps("name")}
                        required
                      />
                    </div>
                    {(formik.touched.name && formik.errors.name) && (
                      <p className="text-danger mb-0">
                        <small>{formik.errors.name}</small>
                      </p>
                    )}
                  </div>
                  <div className="col-12 mb-4">
                    <div className="input-group field-style">
                      <span className="input-group-text" id="basic-addon1">
                        <img src={mailIcon} className="img-fluid" alt="" />
                      </span>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Email Address"
                        aria-label="Username"
                        aria-describedby="basic-addon1"
                        name="username"
                        {...formik.getFieldProps("username")}
                        required
                      />
                    </div>
                    {(formik.touched.username && formik.errors.username) && (
                      <p className="text-danger mb-0">
                        <small>{formik.errors.username}</small>
                      </p>
                    )}
                  </div>
                  <div className="col-12 mb-4">
                    <div className="input-group field-style">
                      <span className="input-group-text" id="basic-addon1">
                        <img src={phoneCallIcon} className="img-fluid" alt="" />
                      </span>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Mobile Number"
                        aria-label="Username"
                        aria-describedby="basic-addon1"
                        name="mobile"
                        {...formik.getFieldProps("mobile")}
                        required
                      />
                    </div>
                    {(formik.touched.mobile && formik.errors.mobile) && (
                      <p className="text-danger mb-0">
                        <small>{formik.errors.mobile}</small>
                      </p>
                    )}
                  </div>
                  <div className="col-12 mb-4">
                    <div className="input-group field-style">
                      <span className="input-group-text" id="basic-addon1">
                        <img src={padLockIcon} className="img-fluid" alt="" />
                      </span>
                      <input
                        type={isViewPassword ? "password" : "text"}
                        className="form-control password"
                        placeholder="Password"
                        aria-label="Username"
                        aria-describedby="basic-addon1"
                        name="password"
                        {...formik.getFieldProps("password")}
                        required
                      />
                      <span class="input-group-text show-icon">
                        {
                          isViewPassword ?
                            <img src={visibleEyeIcon} className="img-fluid svg-icon-size" alt="" onClick={() => setIsViewPassword(!isViewPassword)} />
                            :
                            <img src={hideEyeIcon} className="img-fluid svg-icon-size" alt="" onClick={() => setIsViewPassword(!isViewPassword)} />
                        }
                      </span>
                    </div>
                    {(formik.touched.password && formik.errors.password) && (
                      <p className="text-danger mb-0">
                        <small>{formik.errors.password}</small>
                      </p>
                    )}
                  </div>
                  <div className="col-12 mb-4">
                    <div className="input-group field-style">
                      <span className="input-group-text" id="basic-addon1">
                        <img src={padLockIcon} className="img-fluid" alt="" />
                      </span>
                      <input
                        type={isViewConfirmPassword ? "password" : "text"}
                        className="form-control password"
                        placeholder="Confirm Password"
                        aria-label="Username"
                        aria-describedby="basic-addon1"
                        name="confirmPassword"
                        {...formik.getFieldProps("confirmPassword")}
                        required
                      />
                      <span className="input-group-text show-icon" >
                        {
                          isViewConfirmPassword ?
                            <img src={visibleEyeIcon} className="img-fluid svg-icon-size" alt="" onClick={() => setIsViewConfirmPassword(!isViewConfirmPassword)} />
                            :
                            <img src={hideEyeIcon} className="img-fluid svg-icon-size" alt="" onClick={() => setIsViewConfirmPassword(!isViewConfirmPassword)} />
                        }
                      </span>
                    </div>
                    {(formik.touched.confirmPassword && formik.errors.confirmPassword) && (
                      <p className="text-danger mb-0">
                        <small>{formik.errors.confirmPassword}</small>
                      </p>
                    )}
                  </div>
                  <div className="col-12 mt-4">
                    {showLoader ? (
                      <button type="submit" className="btn mx-auto" disabled>
                        <div className="spinner-border" role="status">
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      </button>
                    ) : (
                      <button type="submit" onClick={formik.handleSubmit} className="btn btn-primary mx-auto">Sign Up</button>
                    )}
                  </div>
                  <div className="col-12 mt-4 text-center">
                    <div className="text-center">Already have an account?
                      <NavLink to="/login" className="text-decoration-underline fw-semibold text-green" style={{ marginLeft: "5px" }}>Log In</NavLink>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
