import { getLoginUserDetail } from "../modules/login/services/loginService";

const isLogin = async (setIsLoading) => {
  const user_id = localStorage.getItem("user_id");
  const access_token = localStorage.getItem("access_token");

  if (user_id && access_token) {
    const res = await getLoginUserDetail({ user_id, access_token });
    if (res?.status) {
      const location = window.location.search;
      const params = new URLSearchParams(location)
      const redirectURL = params.get('redirect_url')
      if (res?.tsp?.is_admin) {
        window.location.replace(`${process.env.REACT_APP_ADMIN_NATURE_URL}?user_id=${user_id}&access_token=${access_token}`);
      } else if (redirectURL) {
        window.location.replace(`${redirectURL}?user_id=${user_id}&access_token=${access_token}`);
      } else {
        window.location.replace(`${process.env.REACT_APP_REDIRECT_URL}?user_id=${user_id}&access_token=${access_token}`);
      }
    }
  }
  setIsLoading(false)
}

export {
  isLogin
}