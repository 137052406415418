export const loginConstants = {
  SET_USER_DETAILS: "SET_USER_DETAILS",
  SET_IS_LOGGED_IN: "SET_IS_LOGGED_IN",
  SET_USERNAME: "SET_USERNAME",
}

const setUserDetails = (values) => {
  return {
    type: loginConstants.SET_USER_DETAILS,
    payload: {
      userDetails: {
        userEmail: values.userEmail,
        userName: values.userName,
        userId: values.userId,
      },
    },
  };
};

const setUserLoginStatus = (values) => {
  return {
    type: loginConstants.SET_IS_LOGGED_IN,
    payload: {
      status: values,
    },
  };
};

const setUserName = (values) => {
  return {
    type: loginConstants.SET_USERNAME,
    payload: {
      username: values.username,
    },
  };
};

export {
  setUserDetails,
  setUserLoginStatus,
  setUserName,
}