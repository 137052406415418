export const homeConstants = {
  SET_HEADER_LIST: "SET_HEADER_LIST",
}

const setHeaderListData = (headerList) => {
  return {
    type: homeConstants.SET_HEADER_LIST,
    payload: {
      headerList,
    },
  };
};

export {
  setHeaderListData
}