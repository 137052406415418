import { ACCESS_TOKEN_TYPES, USER_ID_TYPES } from "../constants/localstorage-types";
import { PROJECT_TYPES } from "../constants/project-types";

const getAccessToken = (tokenType = ACCESS_TOKEN_TYPES[PROJECT_TYPES.PROJECT_TYPES_NATURE]) => {
  const accessToken = localStorage.getItem(tokenType);
  return accessToken;
};

const getUserId = (userIdType = USER_ID_TYPES[PROJECT_TYPES.PROJECT_TYPES_NATURE]) => {
  const userId = localStorage.getItem(userIdType);
  return userId;
};

const clearLocalStorage = () => {
  localStorage.clear();
};

const getUserName = () => {
  const userName = localStorage.getItem("user_name");
  return userName;
};

const getEnergyAccessToken = (tokenType = ACCESS_TOKEN_TYPES[PROJECT_TYPES.PROJECT_TYPES_ENERGY]) => {
  const accessToken = localStorage.getItem(tokenType);
  return accessToken;
};

const getAWSToken = () => {
  const getToken = localStorage.getItem("aws_access_token");
  const data = getToken === null ? "" : getToken;
  return data;
};

const getAWSSession = () => {
  const getToken = localStorage.getItem("aws_session");
  const data = getToken === null ? "" : getToken;
  return data;
};

const getUserEmail = () => {
  const userEmail = localStorage.getItem("user_email");
  const data = userEmail === null ? "" : userEmail;
  return data;
};

const getEnergyId = () => {
  const energyUserId = localStorage.getItem("energy_user_id");
  const result = parseInt(energyUserId, 10);
  return result;
};

const getTSPIds = () => {
  let multi_tsp = localStorage.getItem("multi_tsp");
  if (multi_tsp) {
    multi_tsp = JSON.parse(multi_tsp);
    let person = {};
    Object.keys(multi_tsp).forEach(index => {
      person[index] = multi_tsp[index].user_id;
    });
    return person;
  }
}

const getLastTSPDetail = (data) => {
  let multi_tsp = data?.multi_tsp;
  if (multi_tsp) {
    let person = [];
    Object.keys(multi_tsp).forEach(index => {
      person.push(multi_tsp[index]);
    });
    const totalTSP = person.length - 1;
    return person[totalTSP];
  }
}

const setRegistrationDetail = (data) => {
  localStorage.setItem("name", data?.name);
  localStorage.setItem("email", data?.username);
  localStorage.setItem("mobile", data?.mobile);
}

const getRegistrationDetail = () => {
  const name = localStorage.getItem("name");
  const email = localStorage.getItem("email");
  const mobile = localStorage.getItem("mobile");

  return {
    name,
    email,
    mobile
  }
}

export {
  getAccessToken,
  getUserId,
  clearLocalStorage,
  getUserName,
  getEnergyAccessToken,
  getAWSToken,
  getAWSSession,
  getUserEmail,
  getEnergyId,
  getTSPIds,
  getLastTSPDetail,
  setRegistrationDetail,
  getRegistrationDetail,
};
