/* eslint-disable no-lonely-if */
/* eslint-disable no-else-return */
/* eslint-disable no-negated-condition */
import { setUserDetails, setUserLoginStatus, setUserName } from "../modules/login/redux/loginAction";
import { ACCESS_TOKEN_TYPES, USER_ID_TYPES } from "../constants/localstorage-types";
import { PROJECT_TYPES } from "../constants/project-types";
import { getLastTSPDetail } from "../utils/token";

const loginSubmit = (data, dispatch, showAlert, page = "") => {
  const multi_tsp = getLastTSPDetail(data);
  if (page === "") {
    if (data.otp_verified === false) {
      const values = {
        username: data.tsp.user_email
      };
      showAlert("SUCCUSS", "Success", data.message);
      dispatch(setUserName(values));
      const nav = "/confirm_otp";
      return nav;
    } else {
      if (data.tsp?.aws_session !== "") {
        localStorage.setItem("user_email", data.user_email);
        localStorage.setItem("aws_access_token", data.tsp?.aws_access_token);
        localStorage.setItem("aws_session", data.tsp?.aws_session);
        localStorage.setItem("user_name", data.user_name);
        localStorage.setItem("otp_check", true);
        localStorage.setItem("multi_tsp", data?.multi_tsp ? JSON.stringify(data.multi_tsp) : null);
        localStorage.setItem(USER_ID_TYPES[PROJECT_TYPES.PROJECT_TYPES_ENERGY], multi_tsp?.user_id ? multi_tsp.user_id : null);
        localStorage.setItem(USER_ID_TYPES[PROJECT_TYPES.PROJECT_TYPES_NATURE], data.tsp.user_id);
        const nav = "/submit_otp";
        return nav;
      } else {
        localStorage.setItem("cash_decimal_limit", data.cash_decimal_limit);
        localStorage.setItem("crypto_decimal_limit", data.crypto_decimal_limit);
        localStorage.setItem("sumsub_id", data.sumsub_user_applicant_id);
        localStorage.setItem("kyc_done", data.kyc_done);
        localStorage.setItem("user_email", data.user_email);
        localStorage.setItem("user_name", data.user_name);
        localStorage.setItem("wallet_address", data.tsp.wallet_address);
        localStorage.setItem("energy_wallet_address", multi_tsp?.wallet_address);

        localStorage.setItem("status", data.status);
        localStorage.setItem("otp_check", false);
        localStorage.setItem("aws_access_token", multi_tsp?.aws_access_token);
        localStorage.setItem("aws_session", multi_tsp?.aws_session);
        localStorage.setItem("multi_tsp", data?.multi_tsp ? JSON.stringify(data.multi_tsp) : null);
        localStorage.setItem(ACCESS_TOKEN_TYPES[PROJECT_TYPES.PROJECT_TYPES_NATURE], data.tsp.access_token);
        localStorage.setItem(USER_ID_TYPES[PROJECT_TYPES.PROJECT_TYPES_NATURE], data.tsp.user_id);
        localStorage.setItem(ACCESS_TOKEN_TYPES[PROJECT_TYPES.PROJECT_TYPES_ENERGY], multi_tsp?.access_token ? multi_tsp.access_token : null);
        localStorage.setItem(USER_ID_TYPES[PROJECT_TYPES.PROJECT_TYPES_ENERGY], multi_tsp?.user_id ? multi_tsp.user_id : null);

        const values = {
          userEmail: data.user_email,
          userName: data.user_name,
          userId: data.user_id,
        };

        showAlert("SUCCESS", "Success", "User login Successfully");
        dispatch(setUserLoginStatus(true));
        dispatch(setUserDetails(values));
        const nav = "/dashboard";
        return nav;
      }
    }
  } else {
    localStorage.setItem("cash_decimal_limit", data.cash_decimal_limit);
    localStorage.setItem("crypto_decimal_limit", data.crypto_decimal_limit);
    localStorage.setItem("sumsub_id", data.sumsub_user_applicant_id);
    localStorage.setItem("kyc_done", data.kyc_done);
    localStorage.setItem("user_email", data.user_email);
    localStorage.setItem("user_name", data.user_name);
    localStorage.setItem("wallet_address", data.tsp.wallet_address);
    localStorage.setItem("energy_wallet_address", multi_tsp?.wallet_address);
    localStorage.setItem("status", data.status);
    localStorage.setItem("otp_check", true);
    localStorage.setItem("aws_access_token", multi_tsp?.aws_access_token);
    localStorage.setItem("aws_session", multi_tsp?.aws_session);
    localStorage.setItem("multi_tsp", data?.multi_tsp ? JSON.stringify(data.multi_tsp) : null);
    localStorage.setItem(ACCESS_TOKEN_TYPES[PROJECT_TYPES.PROJECT_TYPES_NATURE], data.tsp.access_token);
    localStorage.setItem(USER_ID_TYPES[PROJECT_TYPES.PROJECT_TYPES_NATURE], data.tsp.user_id);
    localStorage.setItem(ACCESS_TOKEN_TYPES[PROJECT_TYPES.PROJECT_TYPES_ENERGY], multi_tsp?.access_token ? multi_tsp.access_token : null);
    localStorage.setItem(USER_ID_TYPES[PROJECT_TYPES.PROJECT_TYPES_ENERGY], multi_tsp?.user_id ? multi_tsp.user_id : null);

    const values = {
      userEmail: data.user_email,
      userName: data.user_name,
      userId: data.user_id,
    };
    showAlert("SUCCESS", "Success", "User login successfully");
    dispatch(setUserLoginStatus(true));
    dispatch(setUserDetails(values));
    const nav = "/dashboard";
    return nav;
  }
};
export default loginSubmit;
