/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-restricted-globals */
import React, { Fragment } from "react";
import Header from "../../components/layout/header";

const ThemeContainer = ({ type = "public", children, }) => {

  return (
    <Fragment>
      <div className="wrapper w-100 float-start position-relative">
        <Header page_type={type} />
        <div className="">{children}</div>
      </div>
    </Fragment>
  );
};

export default ThemeContainer;
