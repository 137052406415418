/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable prefer-template */
/* eslint-disable camelcase */
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import mainLogo from "../../../assets/images/main-logo-white.png";
import sunsetCapitalLogo from "../../../assets/images/sunset-capital-logo.png";
import ctaIcon from "../../../assets/icons/cta-icon.svg";
import { getPageTypeData } from "../../../modules/home/services/home";
import { setHeaderListData } from "../../../modules/home/redux/homeAction";
import { getUserName } from "../../../utils/token";

const Header = () => {
  const dispatch = useDispatch();

  const name = getUserName();
  let shortName;
  if (name) {
    shortName = name.substring(0, 8) + "...";
  }

  const fetchPageData = async (
    link = "/api/v1/homepage/corporate",
    pageType = "corporate"
  ) => {
    const result = await getPageTypeData(pageType, link);
    if (result) {
      setTimeout(() => {
        dispatch(setHeaderListData(result.header_list));
      });
    } else {
      console.log(result);
    }
  };

  useEffect(() => {
    fetchPageData();
  }, []);

  return (
    <nav className="navbar navshadow-style navbar-expand-lg px-lg-4 px-md-1">
      <div className="container-fluid">
        <a className="navbar-brand" href="index.html">
          <img src={mainLogo} className="img-fluid default-logo" alt="" />
          <img src={sunsetCapitalLogo} className="img-fluid sticky-logo" alt="" />
        </a>
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown"
          aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNavDropdown">
          <ul className="navbar-nav ms-auto">
            <li className="nav-item dropdown">
              <div className="cta-btn">
                <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1"
                  id="Capa_1" x="0px" y="0px" viewBox="0 0 512 512"
                  xmlSpace="preserve">
                  <g>
                    <path
                      d="M129.038,0c-5.523,0-10.001,4.479-10.001,10.001c0,5.523,4.478,10.001,10.001,10.001    c56.493,0,102.453,47.153,102.453,105.115c0,5.523,4.479,10.001,10.001,10.001c5.523,0,10.001-4.479,10.001-10.001    C251.494,56.127,196.56,0,129.038,0z" />
                  </g>
                  <g>
                    <path
                      d="M129.005,47.447c-5.523,0-10.001,4.479-10.001,10.001c0,5.523,4.478,10.001,10.001,10.001    c30.935,0,56.102,25.869,56.102,57.666c0,5.523,4.479,10.001,10.001,10.001c5.523,0,10.001-4.479,10.001-10.001    C205.11,82.289,170.969,47.447,129.005,47.447z" />
                  </g>
                  <g>
                    <path
                      d="M129.199,91.155c-5.524,0-10.001,4.477-10.001,10.001c0,5.523,4.479,10.001,10.001,10.001    c7.227,0,13.105,6.124,13.105,13.652c0,5.523,4.478,10.001,10.001,10.001c5.523,0,10.001-4.479,10.001-10.001    C162.307,106.252,147.454,91.155,129.199,91.155z" />
                  </g>
                  <g>
                    <path
                      d="M458.489,79.265h-22.169c-5.523,0-10.001,4.478-10.001,10.001s4.478,10.001,10.001,10.001h22.169    c18.474,0,33.504,15.03,33.504,33.504V268.53c0,10.637-4.989,20.124-12.742,26.266v-26.154c0-20.404-14.559-37.929-34.617-41.67    l-6.465-1.205c1.442-4.42,2.231-9.13,2.231-14.025v-22.982c0-24.989-20.33-45.319-45.319-45.319s-45.318,20.33-45.318,45.319    v22.982c0,4.895,0.79,9.605,2.231,14.025l-6.465,1.205c-20.059,3.74-34.617,21.265-34.617,41.67v28.993    c-10.108-5.775-16.941-16.654-16.941-29.105V132.771c0-18.474,15.03-33.504,33.504-33.504h23.836    c5.523,0,10.001-4.478,10.001-10.001s-4.479-10.001-10.001-10.001h-23.836c-29.503,0-53.506,24.003-53.506,53.506V268.53    c0,29.503,24.003,53.506,53.506,53.506h50.326v28.755c0,4.039,2.43,7.683,6.159,9.233c1.242,0.517,2.546,0.768,3.84,0.768    c2.592,0,5.141-1.008,7.052-2.906l36.076-35.85h27.561c29.503,0,53.506-24.003,53.506-53.506V132.771    C511.995,103.268,487.992,79.265,458.489,79.265z M369.764,188.76c0-13.96,11.356-25.317,25.316-25.317    s25.317,11.357,25.317,25.317v22.982c0,13.96-11.357,25.317-25.317,25.317c-13.96,0-25.316-11.357-25.316-25.317V188.76z     M459.246,302.013c-0.253,0.006-0.504,0.019-0.759,0.019h-31.683v0.002c-2.642,0-5.176,1.045-7.05,2.906l-21.951,21.814v-14.72    c0-5.523-4.479-10.001-10.001-10.001h-56.887v-33.392c0-10.775,7.689-20.031,18.281-22.007l14.099-2.629    c8.186,8.067,19.412,13.055,31.786,13.055c12.373,0,23.601-4.99,31.787-13.056l14.1,2.629    c10.593,1.976,18.281,11.231,18.281,22.008V302.013z" />
                  </g>
                  <g>
                    <path
                      d="M396.051,82.199c-1.859-1.87-4.44-2.93-7.07-2.93c-2.63,0-5.211,1.06-7.071,2.93c-1.86,1.851-2.93,4.432-2.93,7.071    c0,2.63,1.069,5.211,2.93,7.071c1.86,1.859,4.44,2.93,7.071,2.93c2.63,0,5.211-1.07,7.07-2.93c1.86-1.87,2.931-4.44,2.931-7.071    C398.982,86.63,397.912,84.059,396.051,82.199z" />
                  </g>
                  <g>
                    <path
                      d="M185.878,336.437c-1.86-1.86-4.43-2.93-7.071-2.93c-2.63,0-5.211,1.07-7.07,2.93c-1.86,1.86-2.931,4.44-2.931,7.071    c0,2.64,1.07,5.211,2.931,7.071c1.869,1.87,4.44,2.939,7.07,2.939c2.63,0,5.211-1.069,7.071-2.939    c1.87-1.86,2.93-4.432,2.93-7.071C188.808,340.878,187.748,338.298,185.878,336.437z" />
                  </g>
                  <g>
                    <path
                      d="M404.784,426.87l-76.62-76.621c-10.2-10.2-26.246-11.692-38.15-3.551l-48.902,33.438    c-3.969,2.713-9.315,2.216-12.716-1.185l-16.171-16.172c-3.904-3.903-10.235-3.904-14.145,0c-3.905,3.905-3.905,10.237,0,14.145    l16.171,16.172c10.2,10.199,26.244,11.694,38.15,3.551l48.902-33.437c3.967-2.713,9.315-2.217,12.716,1.184l76.621,76.621    c3.899,3.898,3.898,10.244-0.001,14.145c-49.132,49.132-129.077,49.132-178.209,0L56.848,299.576    c-23.802-23.801-36.908-55.445-36.908-89.105s13.107-65.305,36.909-89.107c1.889-1.889,4.4-2.928,7.071-2.928    c2.672,0,5.184,1.04,7.072,2.929l76.62,76.621c3.399,3.399,3.897,8.748,1.185,12.716l-33.436,48.902    c-8.143,11.905-6.649,27.949,3.55,38.15l24.308,24.308c3.906,3.904,10.237,3.904,14.145,0c3.905-3.905,3.905-10.238,0-14.145    l-24.308-24.308c-3.399-3.399-3.897-8.747-1.184-12.715l33.437-48.902c8.141-11.906,6.647-27.951-3.552-38.15l-76.62-76.621    c-11.698-11.698-30.733-11.698-42.433,0c-56.931,56.932-56.931,149.567,0,206.499l155.582,155.581    C226.752,497.767,264.143,512,301.534,512c37.392-0.001,74.783-14.234,103.249-42.699    C416.481,457.603,416.481,438.569,404.784,426.87z" />
                  </g>
                  <g>
                    <path
                      d="M134.727,245.919l-60.626-60.626c-3.907-3.904-10.238-3.904-14.145,0c-3.905,3.906-3.905,10.239,0,14.145l60.627,60.626    c1.953,1.952,4.511,2.929,7.072,2.929c2.559,0,5.119-0.977,7.072-2.929C138.632,256.158,138.632,249.825,134.727,245.919z" />
                  </g>
                  <g>
                    <path
                      d="M366.761,477.952l-63.789-63.789c-3.907-3.904-10.238-3.904-14.145,0c-3.905,3.906-3.905,10.239,0,14.145l63.789,63.789    c1.953,1.952,4.511,2.929,7.072,2.929c2.559,0,5.119-0.977,7.073-2.929C370.666,488.19,370.666,481.858,366.761,477.952z" />
                  </g>
                </svg>
                <div className="cta-btn-content">
                  <span>call us Now</span>
                  <a href="tel:552799309175" className="text-decoration-none">+55 27 99309 175</a>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default React.memo(Header);
