import { homeConstants } from "./homeAction";

const initialState = {
  headerList: [],
  isLoggedIn: false,
};

const homeReducer = (state = initialState, action) => {
  switch (action.type) {
    case homeConstants.SET_HEADER_LIST:
      return {
        ...state,
        headerList: action.payload.headerList,
      };
    default:
      return state;
  }
};

export default homeReducer