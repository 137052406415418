import React, { useEffect, useState } from 'react';
import { Routes, Route } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import Login from "../modules/login";
import SignUp from "../modules/sign-up";
import ForgotPassword from "../modules/forgot-password";
import ResetPassword from "../modules/reset-password";
import VerifyOtp from '../modules/verify-otp';
import SubmitOtp from '../modules/submit-otp';
import { isLogin } from '../utils/check-login';
import ConfirmOTP from '../modules/confirm-otp';

const RoutesPage = () => {

  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    isLogin(setIsLoading);
  }, []);

  return (
    !isLoading ?
      <Routes>
        <Route exact path="/" element={<Login />} />
        <Route path="/login" element={<Login />} />

        <Route path="/signup" element={<SignUp />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/verify-otp" element={<VerifyOtp />} />
        <Route path="/submit_otp" element={<SubmitOtp />} />
        <Route path="/confirm_otp" element={<ConfirmOTP />} />
      </Routes>
      :
      <div className="Loader2">
        <Spinner animation="border" />
      </div>
  )
}

export default RoutesPage;