const api_end_point = {
  SERVER_IMAGE_FOLDER_URL: process.env.REACT_APP_SERVER_IMAGE_FOLDER_URL,
  SERVER_ENERGY_IMAGE_FOLDER_URL: process.env.REACT_APP_SERVER_ENERGY_IMAGE_FOLDER_URL,
  NATURE_BASE_URL: process.env.REACT_APP_API_NATURE_BASE_URL,
  ENERGY_BASE_URL: process.env.REACT_APP_API_ENERGY_BASE_URL,

  LOGIN_USER_DETAILS: "/api/v1/get/user/details",
  SSO_SIGNUP: "/api/v1/sso/signup",
  SSO_FORGET_PASSWORD: "/api/v1/sso/forget-password",
  SSO_CONFIRM_FORGET_PASSWORD: "/api/v1/sso/confirm-forget-password",
  SSO_RESEND_CODE: "/api/v1/sso/resend-code",
  SSO_CONFIRM_PASSWORD: "/api/v1/sso/confirm-password",
  AUTH_LOGIN: "/api/v1/auth/login",
  AUTH_MULTI_LOGIN: "/api/v1/multi/auth/login",
  FOOTER: "/api/v1/footer",
  ERC_20_VALIDATION: "/api/v1/erc20/token/validation",
  VERIFY_QRCODE: "/api/v1/validate/mfa/code",
};

export default api_end_point;