/* eslint-disable no-negated-condition */
import api_end_point from "../../../API/api-end-point";
import apiServices from "../../../API/api_service";
import { PROJECT_TYPES } from "../../../constants/project-types";

const getFooterData = async (type) => {
  let url;
  if (type !== undefined) {
    url = `${api_end_point.FOOTER}/${type}`;
    if (type === "energy") {
      type = "energy";
    } else {
      type = "nature";
    }
  } else {
    url = `${api_end_point.FOOTER}`;
  }
  const result = await apiServices.get(url, type);
  return result;
};

const subscribeNewsletter = async (values) => {
  const data = {
    email: values.email
  };
  const url = api_end_point.SUBSCRIBE_NEWSLETTER;
  const result = await apiServices.post(url, data);
  return result;
};

const getPageTypeData = async (pageType, link) => {
  // const url = `${api_end_point.HOMEPAGE}/${pageType}`;
  let type = PROJECT_TYPES.PROJECT_TYPES_NATURE;
  const prevPage = localStorage.getItem("pagetype");
  if (pageType === PROJECT_TYPES.PROJECT_TYPES_ENERGY) {
    type = PROJECT_TYPES.PROJECT_TYPES_ENERGY;
  }
  if (prevPage === "energy" && pageType === "investors") {
    link = "/api/v1/get/investor/list/energy";
    type = PROJECT_TYPES.PROJECT_TYPES_ENERGY;
  }
  const result = await apiServices.get(link, type);
  return result;
};

export {
  getFooterData,
  subscribeNewsletter,
  getPageTypeData,
}