import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import OtpInput from "react-otp-input";
import mailIcon from "../../assets/icons/mail-icon.svg";
import padLockIcon from "../../assets/icons/padlock-icon.svg";
import visibleEyeIcon from "../../assets/icons/visible-eye-icon.svg";
import hideEyeIcon from "../../assets/icons/hide-eye-icon.svg";
import useAlert from "../../hooks/use-alert";
import { sSOResendCode, sSOConfirmForgetPassword } from "../forgot-password/services/forgotPasswordServices";

const ResetPassword = () => {
  const navigate = useNavigate();
  const { showAlert } = useAlert();
  const { username } = useSelector((state) => state.loginReducer);
  const userName = localStorage.getItem("username");

  const [isViewPassword, setIsViewPassword] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const initialValues = {
    username: username ? username : userName ? userName : "",
    confirmationCode: "",
    password: "",
  };

  const formSchema = Yup.object().shape({
    confirmationCode: Yup.string()
      .trim()
      .required("Confirmation code must not be empty")
      .test('len', 'Confirmation code must be exactly 6 digits', val => val.length === 6)
      .test('len', 'Confirmation code must be number', val => new RegExp(/^\d+$/).test(parseInt(val))),
    password: Yup.string()
      .trim()
      .required("Password must not be empty")
  })

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: formSchema,
    onSubmit: async (values) => {
      setIsLoading(true);
      const result = await sSOConfirmForgetPassword(values);
      if (result && result.status) {
        showAlert("SUCCESS", "SUCCESS", result.message);
        navigate("/login");
      } else {
        showAlert("ERROR", "ERROR", result.message);
      }

      setIsLoading(false);
    },
  });

  const handleOTPChange = (code) => {
    formik.setFieldValue("confirmationCode", code);
  }

  const handleResendCodeSubmit = async () => {
    const result = await sSOResendCode(formik.values);
    if (result.status) {
      showAlert("SUCCESS", "Success", "Verification code is sent to your email address.");
    } else {
      showAlert("ERROR", "Error", result.message);
    }
  };

  return (
    <div className="page-content bg-style no-sidebar w-100 float-start position-relative">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-lg-5 mb-4">
            <div className="content-box-style">
              <h4 className="text-uppercase text-center mb-5">Reset Password</h4>
              <form action="" className="form-style">
                <div className="row">
                  <div className="col-12 mb-4">
                    <div className="input-group field-style">
                      <span className="input-group-text" id="basic-addon1">
                        <img src={mailIcon} className="img-fluid" alt="" />
                      </span>
                      <input
                        type="Email"
                        className="form-control"
                        placeholder="Input your user ID or Email"
                        aria-label="Username"
                        aria-describedby="basic-addon1"
                        name="username"
                        {...formik.getFieldProps("username")}
                        required
                        disabled
                      />
                    </div>
                    {/* {(formik.touched.username && formik.errors.username) && (
                      <p className="text-danger mb-0">
                        <small>{formik.errors.username}</small>
                      </p>
                    )} */}
                  </div>

                  <div className="col-12 mb-4">
                    <label htmlFor="confirmationCode" className="form-label font-14 font-w-500 font-292930">
                      Confirmation code
                    </label>
                    <div className="otp-inputs">
                      <OtpInput
                        value={formik.values.confirmationCode === undefined ? "" : formik.values.confirmationCode}
                        onChange={handleOTPChange}
                        numInputs={6}
                        name="confirmationCode"
                        separator={<span style={{ width: "8px", marginRight: "5px" }}></span>}
                        isInputNum={true}
                        shouldAutoFocus={true}
                        renderInput={(props) => <input {...props} />}
                      />
                    </div>
                    {(formik.touched.confirmationCode && formik.errors.confirmationCode) && (
                      <p className="text-danger mb-0">
                        <small>{formik.errors.confirmationCode}</small>
                      </p>
                    )}
                  </div>

                  <div className="col-12 mb-4">
                    <div className="input-group field-style">
                      <span className="input-group-text" id="basic-addon1">
                        <img src={padLockIcon} className="img-fluid" alt="" />
                      </span>
                      <input
                        type={isViewPassword ? "password" : "text"}
                        className="form-control password"
                        placeholder="Input your password"
                        aria-label="Username"
                        aria-describedby="basic-addon1"
                        name="password"
                        autoComplete="new-password"
                        {...formik.getFieldProps("password")}
                        required
                      />
                      <span className="input-group-text show-icon">
                        {
                          isViewPassword ?
                            <img src={visibleEyeIcon} className="img-fluid svg-icon-size" alt="" onClick={() => setIsViewPassword(!isViewPassword)} />
                            :
                            <img src={hideEyeIcon} className="img-fluid svg-icon-size" alt="" onClick={() => setIsViewPassword(!isViewPassword)} />
                        }
                      </span>
                    </div>
                    {(formik.touched.password && formik.errors.password) && (
                      <p className="text-danger mb-0">
                        <small>{formik.errors.password}</small>
                      </p>
                    )}
                  </div>
                  <span className="col-12 text-end text-green cursor-pointer" onClick={() => handleResendCodeSubmit()}>
                    Resend Code?
                  </span>
                  <div className="col-12 mt-4">
                    {
                      isLoading ?
                        <button type="submit" className="btn mx-auto" disabled>
                          <div className="spinner-border" role="status">
                            <span className="visually-hidden">Loading...</span>
                          </div>
                        </button>
                        :
                        <button type="submit" onClick={formik.handleSubmit} className="btn btn-primary mx-auto">Confirm Email Address</button>
                    }
                  </div>
                  <div className="col-12 mt-4 text-center">
                    <NavLink to="/login" className="text-decoration-none fw-semibold text-green">
                      <svg xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink" style={{ height: "25px", width: "25px;" }} version="1.1" id="Layer_1"
                        x="0px" y="0px" viewBox="0 0 512 512" xmlSpace="preserve">
                        <g id="_19">
                          <path
                            d="M255.8,405.4c-5.6,0-11-2.2-14.9-6.2l-128-128c-8.3-8.3-8.3-21.8,0-30.1l128-128c8-6.8,19.8-6.8,27.8,0   c8.9,7.7,10,21.1,2.3,30.1L157.9,256L271,368.9c4.1,4,6.3,9.5,6.3,15.3C277.2,395.9,267.6,405.4,255.8,405.4L255.8,405.4z" />
                          <path
                            d="M368.7,399l-128-128c-8.3-8.3-8.3-21.8,0-30.1l128-128c8.4-8.4,21.9-8.4,30.3,0s8.4,21.9,0,30.3L285.9,256L399,368.9   c4.1,4,6.3,9.5,6.3,15.3c-0.1,11.8-9.7,21.3-21.5,21.2C378.1,405.4,372.7,403,368.7,399z" />
                        </g>
                      </svg>
                      Back to Log In
                    </NavLink>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
